<template>
    <div class="schemem">
        <div class="scheme_inner">
            <div class="title">
                <div class="img_box"></div>
                <h2>
                    <slot name="title"></slot>
                </h2>
                <div class="img_box"></div>
            </div>
            <div class="sub_title">
                <slot name="subTitle"></slot>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>
.schemem{
display: flex;
justify-content: center;
    .scheme_inner{
        .title{
            display: flex;
            align-items: center;
            justify-content: center;
            .img_box{
                width: 99px;
                height: 23px;
                background: url('~@/assets/images/baiti_zuo.png');
                &:last-of-type{
                    transform: rotate(180deg);
                }
            }
            h2{
                line-height: 1;
                font-size: 36px;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #282828;
                padding: 0 33px;
            }
        }
        .sub_title{
            text-align: center;
            margin-top: 39px;
            font-size: 18px;
            font-family: Source Han Sans CN;
            color: #666666;
        }
    }
}
</style>